import React, { useState } from 'react';
import Section from '../Div'; // your layout component
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// -----------------------------------------------
// Mapping of sub-options to their descriptive text
// -----------------------------------------------
const descriptionsMapping = {
  '+EV': `The +EV model collects and displays player prop odds from DraftKings to take advantage of PrizePicks and Underdogs set payout system. The +EV Model shows you which player props are most favored by sports books so you’re always betting on the favorite when the payout odds are set.`,

  'HotStreaks NBA': `The Hot Streaks Model collects and displays player props that are currently on streaks hitting the over/under consistently in their most recent games.`,

  'NBA Projections': `The NFL/NBA/MLB projections model is designed to predict the outcome of player props based on current matchup and recent games.`,

  'Opponent Points per Game': `Displays NBA team points, rebounds, and assist ALLOWED per game. These models highlight team defensive stats to ensure you’re only betting on the best matchups each day.`,

  'Opponent Rebounds per Game': `Displays NBA team points, rebounds, and assist ALLOWED per game. These models highlight team defensive stats to ensure you’re only betting on the best matchups each day.`,

  'Opponent Assists per Game': `Displays NBA team points, rebounds, and assist ALLOWED per game. These models highlight team defensive stats to ensure you’re only betting on the best matchups each day.`,

  'Team Strikeouts per Game': `Displays each MLB team's average total strikeouts and hits per game. This helps you know which pitchers are facing the best and worst lineups each day.`,

  'Team Hits per Game': `Displays each MLB team's average total strikeouts and hits per game. This helps you know which pitchers are facing the best and worst lineups each day.`,

  'ColdHot Matchup': `Displays which hitters have a hot or cold matchup against that day's opposing pitcher. This model highlights which hitters are expected to have a good or bad game based on previous matchups against the same pitcher.`,

  'HotStreaks MLB': `The Hot Streaks Model collects and displays player props that are currently on streaks hitting the over/under consistently in their most recent games.`,

  '1st Inning Runs': `Displays each MLB team's average runs scored in the first inning. This model helps take advantage of the yes/no runs first inning betting prop, ensuring you’re aware of which teams are best and worst in the first inning.`,

  'MLB Projections': `The NFL/NBA/MLB projections model is designed to predict the outcome of player props based on current matchup and recent games.`,

  'HotStreaks NFL': `The Hot Streaks Model collects and displays player props that are currently on streaks hitting the over/under consistently in their most recent games.`,

  'Rushing Yards Allowed': `Displays each NFL team's average pass yards and rush yards allowed per game. This ensures you know which players are facing the best and worst defenses each week.`,

  'Pass Yards Allowed': `Displays each NFL team's average pass yards and rush yards allowed per game. This ensures you know which players are facing the best and worst defenses each week.`,

  'NFL Projections': `The NFL/NBA/MLB projections model is designed to predict the outcome of player props based on current matchup and recent games.`
};

// -----------------------------------------------
// Sample data. In a real app, fetch from your API
// -----------------------------------------------
const sportsData = {
  NBA: {
    sidebarOptions: [
      'Opponent Points per Game',
      'Opponent Rebounds per Game',
      'Opponent Assists per Game',
      'HotStreaks NBA',
      'NBA Projections',
    ],
    tables: {
      'Opponent Points per Game': [
        { player: 'LeBron James', points: 29, rebounds: 8 },
        { player: 'Stephen Curry', points: 31, rebounds: 5 },
      ],
      'Opponent Rebounds per Game': [
        { player: 'Nikola Jokić', rebounds: 10 },
        { player: 'Joel Embiid', rebounds: 11 },
      ],
      'Opponent Assists per Game': [
        { player: 'Chris Paul', assists: 11 },
        { player: 'Trae Young', assists: 9 },
      ],
      'HotStreaks NBA': [
        { player: 'Kevin Durant', last5: [28, 30, 34, 29, 35] },
        { player: 'Giannis Antetokounmpo', last5: [32, 27, 33, 36, 31] },
      ],
      'NBA Projections': [
        { player: 'Luka Dončić', projPoints: 30, projAssists: 8 },
        { player: 'Jayson Tatum', projPoints: 27, projRebounds: 7 },
      ],
    },
  },
  MLB: {
    sidebarOptions: [
      'Team Strikeouts per Game',
      'Team Hits per Game',
      'ColdHot Matchup',
      'HotStreaks MLB',
      '1st Inning Runs',
      'MLB Projections',
    ],
    tables: {
      'Team Strikeouts per Game': [
        { team: 'Yankees', strikeouts: 9 },
        { team: 'Red Sox', strikeouts: 8 },
      ],
      'Team Hits per Game': [
        { team: 'Dodgers', hits: 8 },
        { team: 'Mets', hits: 9 },
      ],
      'ColdHot Matchup': [
        { matchup: 'Yankees vs Red Sox', rating: 'Hot' },
        { matchup: 'Cubs vs Cardinals', rating: 'Cold' },
      ],
      'HotStreaks MLB': [
        { player: 'Aaron Judge', homersLast5: [1, 0, 2, 1, 1] },
        { player: 'Mookie Betts', homersLast5: [0, 1, 1, 2, 0] },
      ],
      '1st Inning Runs': [
        { game: 'Giants vs Dodgers', firstInningRuns: 2 },
        { game: 'Astros vs Angels', firstInningRuns: 1 },
      ],
      'MLB Projections': [
        { team: 'Braves', projectedRuns: 5 },
        { team: 'Phillies', projectedRuns: 4 },
      ],
    },
  },
  NFL: {
    sidebarOptions: [
      'HotStreaks NFL',
      'Rushing Yards Allowed',
      'Pass Yards Allowed',
      'NFL Projections',
    ],
    tables: {
      'HotStreaks NFL': [
        { player: 'Patrick Mahomes', last5TDs: [3, 2, 3, 4, 3] },
        { player: 'Josh Allen', last5TDs: [2, 3, 3, 2, 4] },
      ],
      'Rushing Yards Allowed': [
        { team: '49ers', yardsAllowed: 85 },
        { team: 'Ravens', yardsAllowed: 95 },
      ],
      'Pass Yards Allowed': [
        { team: 'Chiefs', passYardsAllowed: 250 },
        { team: 'Bills', passYardsAllowed: 210 },
      ],
      'NFL Projections': [
        { team: 'Cowboys', projectedScore: 24 },
        { team: 'Packers', projectedScore: 20 },
      ],
    },
  },
  EV: {
    sidebarOptions: ['+EV'],
    tables: {
      '+EV': [
        { pick: 'NBA Player Over Points', expectedValue: '+3%' },
        { pick: 'MLB Team Over Runs', expectedValue: '+1.5%' },
      ],
    },
  },
};

export default function SportsSidebar() {
  const [activeTab, setActiveTab] = useState('NBA');
  const [selectedOption, setSelectedOption] = useState(
    sportsData['NBA'].sidebarOptions[0]
  );

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    setSelectedOption(sportsData[newTab].sidebarOptions[0]);
  };

  const tableData = sportsData[activeTab].tables[selectedOption] || [];

  const sidebarStyle = {
    backgroundColor: '#2b2b2b',
    minHeight: '100%',
    padding: '1rem',       
    borderRight: '1px solid #444',
    borderRadius: '5px',
  };

  const sidebarItemStyle = (option) => ({
    padding: '0.6rem 0.8rem',
    marginBottom: '0.2rem',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: selectedOption === option ? '#444' : 'transparent',
    color: '#fff',
    transition: 'background 0.2s',
  });

  return (
    <Section
      className="position-relative"
      style={{ minHeight: '50vh', padding: '1rem', backgroundColor: '#121212' }}
    >
      {/* ------------------ TOP TOGGLES ------------------ */}
      <Section style={{ width: '100%', textAlign: 'center', marginBottom: '0.5rem' }}>
        <ul
          className="cs-tab_links cs-style1 cs-primary_font"
          style={{ position: 'relative', top: '0', display: 'inline-block' }}
        >
          {['NBA', 'MLB', 'NFL', 'EV'].map((sport, index) => (
            <li
              key={index}
              className={activeTab === sport ? 'active mr-3' : 'mr-3'}
              onClick={() => handleTabChange(sport)}
              style={{ cursor: 'pointer', marginRight: '1.5rem', fontSize: '1.1rem' }}
            >
              {sport === 'EV' ? '+EV' : sport}
            </li>
          ))}
        </ul>
      </Section>

      {/* ------------------ DESCRIPTION BLOCK ------------------ */}
      <Section style={{ marginBottom: '2rem', color: '#bbb', border: '1px solid', padding: '15px', borderRadius: '15px' }}>
        {descriptionsMapping[selectedOption] ? (
          <p style={{ whiteSpace: 'pre-line', margin: '0' }}>
            {descriptionsMapping[selectedOption]}
          </p>
        ) : (
          <p style={{ margin: '0' }}>
            Select a model from the sidebar to see its description.
          </p>
        )}
      </Section>

      {/* ------------------ LAYOUT: LEFT SIDEBAR + RIGHT TABLE ------------------ */}
      <Section
        className="row"
        style={{ backgroundColor: '#1c1c1c', padding: '1rem', borderRadius: '8px' }}
      >
        {/* Left Sidebar */}
        <Section className="col-lg-3" style={sidebarStyle}>
          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
            {sportsData[activeTab].sidebarOptions.map((option, index) => (
              <li
                key={index}
                style={sidebarItemStyle(option)}
                onClick={() => setSelectedOption(option)}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor =
                    selectedOption === option ? '#444' : '#555';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor =
                    selectedOption === option ? '#444' : 'transparent';
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </Section>

        {/* Right Content: Table */}
        <Section className="col-lg-9" style={{ paddingLeft: '1rem' }}>
          <h3 style={{ marginBottom: '1rem', color: '#f1f1f1' }}>
            {activeTab} &gt; {selectedOption}
          </h3>

          <DataTable
            value={tableData}
            rows={5}
            responsiveLayout="scroll"
            className="p-datatable-striped p-datatable-gridlines"
            style={{ backgroundColor: '#1c1c1c', color: '#fff' }}
          >
            {tableData.length > 0 ? (
              // Dynamically render columns from object keys
              Object.keys(tableData[0]).map((key) => (
                <Column key={key} field={key} header={key.toUpperCase()} />
              ))
            ) : (
              // Fallback if no data is available
              <Column field="" header="" body={() => 'No data available'} />
            )}
          </DataTable>
        </Section>
      </Section>
    </Section>
  );
}
