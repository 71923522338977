import React, { createContext, useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { ProgressSpinner } from "primereact/progressspinner";

const DataContext = createContext();

const GET_ALL_DATA = gql`
  query GetAllData {
    playerPropsMLB: playerProps(league: "MLB") {
      name
      prop
      projDiff
      overUnder
    }
    playerPropsNBA: playerProps(league: "NBA") {
      name
      prop
      projDiff
      overUnder
    }
    playerPropsNFL: playerProps(league: "NFL") {
      name
      prop
      projDiff
      overUnder
    }
    baseballMatchupStats: baseballMatchupStats {
      name
      pos
      team
      home
      time
      opp
      pitcher
      ab
      h
      xbh
      hr
      rbi
      bb
      k
      avg
      obp
      slg
      ops
    }
    pointsPerGame: scrapeDataByConfig(configName: "Opponent Points per game") {
      team
      home
      away
      __typename
    }
    hitsPerGame: scrapeDataByConfig(configName: "Team hits per game") {
      team
      home
      away
    }
    strikeoutsPerGame: scrapeDataByConfig(
      configName: "Team strikeouts per game"
    ) {
      team
      home
      away
    }
    reboundsPerGame: scrapeDataByConfig(
      configName: "Opponent Rebounds per game"
    ) {
      team
      home
      away
    }
    assistsPerGame: scrapeDataByConfig(configName: "Opponent Assist per game") {
      team
      home
      away
    }
    iningsRuns: scrapeDataByConfig(configName: "1st Inning Runs") {
      team
      home
      away
    }
    playerStats: playerStats {
      Category
      PlayerName
      Prizepicks
      Draftkings
      OverUnder
    }
    hotStreaksMLB: hotStreaks(configName: "Hot Streaks MLB") {
      Player
      Matchup
      Streak
      Prop
    }
    hotStreaksNBA: hotStreaks(configName: "HotStreaks NBA") {
      Player
      Matchup
      Streak
      Prop
    }
    hotStreaksNFL: hotStreaks(configName: "HotStreaks NFL") {
      Player
      Matchup
      Streak
      Prop
    }
  }
`;

const DataProvider = ({ children }) => {
  const { loading, error, data } = useQuery(GET_ALL_DATA);
  const [cachedData, setCachedData] = useState(null);

  useEffect(() => {
    console.log("Data loaded:::", data);
    if (data) {
      setCachedData(data);
    }
  }, [data]);

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ProgressSpinner
          style={{ width: "50px", height: "50px" }}
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </div>
    );
  if (error) {
    console.error("GraphQL Error:", error);
    return <p>Error loading data from graphql. Please try again.</p>;
  }
  return (
    <DataContext.Provider value={cachedData || {}}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
