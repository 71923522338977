import React, { useEffect } from "react";
import CaseStudy from "../components/CaseStudy";
import FunFact from "../components/FunFact";
import Hero from "../components/Hero";
import Div from "../components/Div";
import Spacing from "../components/Spacing";
import Cta from "../components/Cta";
import { pageTitle } from "../helper";
import SectionHeading from "../components/SectionHeading";
import Button from "../components/Button";
import SportsSidebar from "../components/SportsSidebar";
import ModelDetails from "../components/ModelDetails";

export default function Home() {
  pageTitle("Home");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="Gambling Insights <br />at Your Fingertips."
        btnText="Login"
        btnLink="/login"
        bgImageUrl="/images/hero_bg.jpeg"
      />
      {/* End Hero Section */}

      <Div className="container">

        <Div className="row pt-4">
            <Div className="col-lg-6">
              <div style={{ height: "325px" }} className="cs-pricing_table cs-style1">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "35px 0 0 0",
                  }}
                >
                  <svg
                    width="60px"
                    height="60px"
                    viewBox="0 0 24.00 24.00"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#FF4A17"
                    stroke-width="0.00024000000000000003"
                    transform="matrix(1, 0, 0, 1, 0, 0)"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M19 1C19 0.447715 19.4477 0 20 0C20.5523 0 21 0.447715 21 1V1.58582L22.2709 0.314894C22.6614 -0.0756305 23.2946 -0.0756294 23.6851 0.314895C24.0757 0.705419 24.0757 1.33858 23.6851 1.72911L22.4142 3H23C23.5523 3 24 3.44772 24 4C24 4.55228 23.5523 5 23 5H20.4142L12.7017 12.7125C12.3112 13.103 11.678 13.103 11.2875 12.7125C10.897 12.322 10.897 11.6888 11.2875 11.2983L19 3.58582V1Z"
                        fill="#FF4A17"
                      ></path>{" "}
                      <path
                        d="M17.3924 3.78908C17.834 3.3475 17.7677 2.61075 17.2182 2.31408C15.6655 1.47581 13.8883 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 10.1154 22.5261 8.34153 21.6909 6.79102C21.3946 6.24091 20.6574 6.17424 20.2155 6.61606L20.1856 6.64598C19.8554 6.97615 19.8032 7.48834 20.016 7.90397C20.6451 9.1326 21 10.5249 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C13.4782 3 14.8732 3.35638 16.1037 3.98791C16.5195 4.20129 17.0322 4.14929 17.3627 3.81884L17.3924 3.78908Z"
                        fill="#FF4A17"
                      ></path>{" "}
                      <path
                        d="M14.3899 6.79159C14.8625 6.31902 14.7436 5.52327 14.1062 5.32241C13.4415 5.11295 12.7339 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 11.2702 18.8883 10.5664 18.6811 9.9049C18.4811 9.26659 17.6846 9.14697 17.2117 9.61995L17.1194 9.71224C16.8382 9.99337 16.7595 10.4124 16.8547 10.7984C16.9496 11.1833 17 11.5858 17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C12.4172 7 12.8225 7.0511 13.21 7.1474C13.5965 7.24347 14.0166 7.16496 14.2982 6.88331L14.3899 6.79159Z"
                        fill="#FF4A17"
                      ></path>{" "}
                      <path
                        d="M11.078 9.15136C11.4874 9.01484 11.6934 9.48809 11.3882 9.79329L10.5827 10.5989C9.80254 11.379 9.80254 12.6438 10.5827 13.4239C11.3628 14.204 12.6276 14.204 13.4077 13.4239L14.2031 12.6285C14.5089 12.3227 14.9822 12.5301 14.8429 12.9397C14.441 14.1209 13.3135 15 12 15C10.3431 15 9 13.6569 9 12C9 10.6796 9.88827 9.54802 11.078 9.15136Z"
                        fill="#FF4A17"
                      ></path>{" "}
                    </g>
                  </svg>
                  <h2
                    style={{
                      padding: "15px 0 20px",
                      borderBottom: "none",
                      fontWeight: "500",
                    }}
                    className="cs-pricing_title text-center"
                  >
                    Our Mission
                  </h2>
                </div>
                <div style={{ borderBottom: "none" }} className="cs-pricing_info">
                  <div className="cs-price_text">
                    Beat The Books. We believe every bet should be placed with the
                    value on your side.
                  </div>
                </div>
              </div>
              <Spacing lg="25" md="25" />
            </Div>
            <Div className="col-lg-6">
              <div style={{ height: "325px" }} className="cs-pricing_table cs-style1">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "35px 0 0 0",
                  }}
                >
                  <svg
                    fill="#FF4A17"
                    height="64px"
                    width="64px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 379.57 379.57"
                    stroke="#FF4A17"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path d="M320.962,123.055c-30.868-24.218-78.682-53.087-131.177-53.087c-52.263,0-100.19,29.226-131.192,53.744 C27.928,147.963,0,179.456,0,189.785c0,11.773,26.846,42.62,58.623,67.36c25.163,19.591,75.664,52.457,131.162,52.457 c55.526,0,106.01-32.82,131.161-52.384c31.778-24.719,58.625-55.599,58.625-67.433C379.57,178.732,352.183,147.55,320.962,123.055z M189.785,268.254c-43.337,0-78.468-35.132-78.468-78.468c0-43.337,35.131-78.468,78.468-78.468s78.468,35.131,78.468,78.468 C268.253,233.122,233.122,268.254,189.785,268.254z"></path>{" "}
                    </g>
                  </svg>
                  <h2
                    style={{
                      padding: "15px 0 20px",
                      borderBottom: "none",
                      fontWeight: "500",
                    }}
                    className="cs-pricing_title text-center"
                  >
                    Our Vision
                  </h2>
                </div>
                <div style={{ borderBottom: "none" }} className="cs-pricing_info">
                  <div className="cs-price_text">
                    Gambling Forecast was created to gather the most relevant
                    gambling data and display it In an easy to read format to
                    elevate your gambling experience. Our vision is to create the
                    best advantage gambling website by only posting data models
                    with proven success.
                  </div>
                </div>
              </div>
              <Spacing lg="25" md="25" />
            </Div>
          </Div>

      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6">
            <SectionHeading
              title="Mission Statement"
              subtitle="About Us"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Gambling Forecast was created to gather the most relevant gambling data and display it In an easy to read format to elevate your gambling experience. Our vision is to create the best advantage gambling website by only posting data models with proven success.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
              <Button
                btnLink="/about"
                btnText="Learn More"
                className="custom-login"
              />
              <Spacing lg="25" md="25" />
            </SectionHeading>
          </Div>
          <Div className="col-xl-6 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="50" md="50" />

      <ModelDetails />

      <SportsSidebar />

      <Spacing lg="30" md="30" />

      <Div className="container text-center">

        <Button btnLink="/pricing" btnText="Pricing" className="custom-login"/>
      </Div>

      {/* End CTA Section */}
    </>
  );
}
