import React from 'react';
import 'primereact/resources/primereact.min.css';
import './index.css'

const ModelDetails = () => {
  return (
    <div className="prop-dashboard">
      {/* Hero Section */}
      <section className="hero-section text-center">
        <h1>Find Your Edge, Quickly.</h1>
        <h2>The Ultimate Props Dashboard</h2>
      </section>

      {/* Main Content */}
      <div className="main-content" style={{ marginTop: '70px' }}>
        {/* Features List */}
        <div className="features-list">
          {/* Feature 1 */}
          <div className="feature-item flex align-items-start mb-6">
            <img src="/images/crown.png" alt="Crown Icon" className="feature-icon mr-4 large-crown-icon crown"/>
            <div>
              <h3>Thousands of, Sorted by Probability</h3>
              <p>Quickly find the props that are most likely to win across multiple sports and a variety of prop sites.</p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="feature-item flex align-items-start mb-6">
            <img src="/images/crown.png" alt="Crown Icon" className="feature-icon mr-4 large-crown-icon crown" />
            <div>
              <h3>Expected Value Calculator</h3>
              <p>Choose your favorite props and calculate how the size of your advantage.</p>
            </div>
          </div>

          {/* Feature 3 */}
          <div className="feature-item flex align-items-start mb-6">
            <img src="/images/crown.png" alt="Crown Icon" className="feature-icon mr-4 large-crown-icon crown" />
            <div>
              <h3>Find Favorable Lines</h3>
              <p>Discover the lines that are too short or too long, giving you an extra advantage when it’s time to wager.</p>
            </div>
          </div>
        </div>
      </div>

      <style jsx global>{`
        body {
          background-color: #1a1a1a;
          color: #ffffff;
        }
        
        .p-card {
          background: #2d2d2d !important;
          color: #ffffff !important;
        }
      `}</style>

      <style jsx>{`
        .prop-dashboard {
          max-width: 1200px;
          margin: 0 auto;
          padding: 2rem;
        }

        .hero-section h1 {
          font-size: 2.5rem;
          margin-bottom: 1rem;
          color: #ff6b35;
        }

        .hero-section h2 {
          font-size: 1.8rem;
          color: #cccccc;
          margin-bottom: 2rem;
        }

        .features-list {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .feature-item {
          width: 100%;
          max-width: 800px;
          display: flex;
          align-items: flex-start;
        }

        .feature-icon {
          font-size: 2rem;
          color: #ff6b35;
        }

        .large-crown-icon {
          font-size: 3rem;
        }

        .sub-feature {
          padding-left: 2rem;
          border-left: 2px solid #ff6b35;
        }

        .orange-icon {
          color: #ff6b35 !important;
          background: transparent !important;
        }

        .orange-button {
          background-color: #ff6b35 !important;
          border-color: #ff6b35 !important;
        }

        .orange-outline {
          color: #ff6b35 !important;
          border-color: #ff6b35 !important;
        }

        /* Mobile Styles: Maintain row layout */
        @media (max-width: 768px) {
          .prop-dashboard {
            padding: 1rem;
          }
          
          .hero-section h1 {
            font-size: 2rem;
          }
          
          .hero-section h2 {
            font-size: 1.4rem;
          }
          
          /* Keep .feature-item in row direction */
          .feature-item {
            flex-direction: row; /* Ensures image remains on the left */
            align-items: center;
            text-align: left;
          }

          /* Adjust spacing if necessary */
          .feature-icon {
            margin-bottom: 0; /* Remove bottom margin since items are in a row */
            margin-right: 10px; /* You can adjust this if needed */
          }

          .sub-feature {
            padding-left: 2rem;
            border-left: 2px solid #ff6b35;
          }
        }
      `}</style>
    </div>
  );
};

export default ModelDetails;
